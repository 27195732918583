const HowPlayAreaTwo = () => {

  return (
    <section className="how-play">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="section-heading">
              <h5 className="subtitle">
                How To Play DashLeague
              </h5>
              <h2 className="title">
                Step By Step Guide<br/><small>(For NFT Beginners)</small>
              </h2>
              <p className="text">
                If you are new to the world of crypto and NFTs, then follow the steps below and watch the video to get
                started!
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-h-play">
              <img src="assets/images/metamask.png" alt=""/>
                <p className="steps">
                  FIRST STEP <i className="fas fa-arrow-right"/>
                </p>
                <h4 className="title">
                  Install MetaMask
                </h4>
                <p><a href="https://metamask.io/" target="_blank" className="text-orange">Click here to download </a>
                </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-h-play">
              <img src="assets/images/coinbase.png" alt=""/>
                <p className="steps">
                  SECOND STEP <i className="fas fa-arrow-right"/>
                </p>
                <h4 className="title">
                  Setup Coinbase
                </h4>
                <p><a href="https://www.coinbase.com/" target="_blank" className="text-orange">Click here to setup</a>
                </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-h-play">
              <img src="assets/images/fund.png" alt=""/>
                <p className="steps">
                  THIRD STEP <i className="fas fa-arrow-right"/>
                </p>
                <h4 className="title">
                  Send ETH to MetaMask
                </h4>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-h-play">
              <img src="assets/images/logo.png" alt=""/>
                <p className="steps">
                  FINAL STEP
                </p>
                <h4 className="title">
                  Connect Wallet & Mint A Character
                </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};
export default HowPlayAreaTwo;