import React, {useEffect, useState} from 'react';
import {
  accountSelector,
  contractSelector,
  metadataSelector,
  networkSelector,
  nftStateSelector
} from "../store/selectors";
import {connect} from "react-redux";
import axios from 'axios';

const Dasher = ({id, contract}) => {
  const [json, setJson] = useState({});

  useEffect(()=>{

    const loadNftJson = async () => {
      const jsonUrl = await contract.methods.tokenURI(id).call();
      const _json = await axios.get(jsonUrl);
      setJson(_json.data);
    };
    loadNftJson();

  }, [])

  if(! json.image) return 'loading ..';

  const split = json.name.split('#');

  return (
    <div className="col-lg-4">
      <div className="single-winer">
        <div className="top-area">
          <div className="left">
            <h4 className="name">
              Dasher<br/>#{split[1]}
            </h4>
            <p className="date">

            </p>
          </div>
          <div className="right">
            <p className="id">  {json.attributes[0].value}</p>
          </div>
        </div>
        <div className="bottom-area">
          <video src={json.image} alt="" loop muted autoPlay style={{maxWidth: '100%'}}/>
        </div>
      </div>
    </div>
  )
};
function mapStateToProps(state) {
  return {
    contract: contractSelector(state)
  }
}

export default connect(mapStateToProps)(Dasher)