import React, {useEffect, useState} from 'react';
import {
  accountSelector,
  contractSelector,
  metadataLoadedSelector,
  metadataSelector, networkSelector,
  nftStateLoadedSelector,
  nftStateSelector
} from "../store/selectors";
import {connect} from "react-redux";
import Contract from "../abis/DashLeague.json";
import map from 'lodash/map';
import Dasher from './Dasher';
import ConnectWalletButton from "./ConnectWalletButton";

const MyDashers = ({contract, account, dataLoaded}) => {

  const [nfts, setNfts] = useState([]);

  useEffect(()=>{
    if(account && contract){
      const getNfts = async () => {
        const nfts = await contract.methods.tokensOfOwner(account).call();
        setNfts(nfts);
      }
      getNfts();
    }
  }, [account, contract])



  if(! account) {
    return (
      <>
        <div style={{display: 'block', margin:'auto', width: '100%'}}>
          <p style={{textAlign: 'center', color: 'white', marginBottom: '25px'}}>Connect MetaMask Wallet To View Your Dashers</p>
        </div>
        <div style={{display: 'block', margin: 'auto', width: '100%'}}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <ConnectWalletButton />
          </div>
        </div>
      </>
    )
  }

  if(! nfts.length) {
    return 'got 0 nfts';
  }

  return map(nfts, (nft, index) => <Dasher id={nft} key={index}/>)
}
function mapStateToProps(state) {

  return {
    metadata: metadataSelector(state),
    contract: contractSelector(state),
    nftState: nftStateSelector(state),
    network: networkSelector(state),
    account: accountSelector(state)
  }
}

export default connect(mapStateToProps)(MyDashers)