import React from 'react';
import MyDashers from "./MyDashers";

const RecentWinnersArea = () => {

  return (
    <section className="recent-winners"id="mydashers" >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="section-heading">
              {/*<h5 className="subtitle">*/}
              {/*  Check out the dashers you've minted so far*/}
              {/*</h5>*/}
              <h2 className="title">
                MY DASHERS
              </h2>
              <p className="text">
                Check out the dashers you've minted so far
                {/*Looking to join the action?<br/> Click the button below to grab your <span*/}
                {/*className="text-orange">DashLeague</span><span className="text-seagreen"> NFT</span> on <span*/}
                {/*className="text-pink">Opensea</span>!*/}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <MyDashers />
        </div>
        <div className="row" style={{paddingTop: '120px', paddingBottom: '40px'}}>
          <div className="col-lg-12 text-xl-center">
            <a style={{background: 'lightseagreen'}} className="mybtn1" href="https://opensea.io/collection/dashleague" target="_blank">View DashLeague On
              OpenSea </a>
          </div>
        </div>
      </div>
    </section>
)

};
export default RecentWinnersArea