import React from 'react';
import ConnectWalletButton from "./ConnectWalletButton";
import {isCorrectNetwork} from "../utils/MetaMask";
import {accountSelector, networkSelector} from "../store/selectors";
import {connect} from "react-redux";

const Header = ({account, network}) => {
  return (
    <>
      {/*<div className="custom-cursor" />*/}
      <header className="header mobile" >

        <div className="mainmenu-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <a className="navbar-brand" href="index.html">
                    <img src="assets/images/logo.png" alt="" />
                  </a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu" aria-controls="main_menu"
                          aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className="collapse navbar-collapse fixed-height" id="main_menu">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <a className="nav-link" href="/">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#about">
                          <span>About</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#roadmap">
                          <span>Roadmap</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="https://jp0-1.gitbook.io/dashleague/" target="_blank">
                          <span>White Paper</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#gameplay-s">
                          <span>GamePlay</span>
                        </a>
                      </li>
                      {/*<li className="nav-item">*/}
                      {/*  <a className="nav-link" href="https://discord.gg/mqEY4N4bEa" target="_blank">*/}
                      {/*    <span>Community</span>*/}
                      {/*  </a>*/}
                      {/*</li>*/}
                      <li className="nav-item">
                        <a className="nav-link" href="#faq">
                          <span>FAQ</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="https://app.dashleague.io/dashers" target="_blank">
                          <span>Play</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>

      </header>


      <header className="header desktop">

        <div className="header-wrapper">
          <div className="row g-0 no-gutters">

            <div className="col-lg-1 align-items-center">
              <div className="edge-option align-middle">
                <a href="#" className="search"><i className="fa fa-search fa-lg"></i></a>
              </div>
            </div>



            <div className="col-lg-3">
              <div className="header-left-border" />
              <div className="header-top-nav text-left">
                <span>Follow us </span>
                <ul className="icons" style={{paddingTop: '17px'}}>
                  {/*<li>*/}
                  {/*  <a href="#">*/}
                  {/*    <i className="fab fa-facebook-square" />*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  <li>
                    <a href="https://twitter.com/playdashleague" target="_blank">
                      <i className="fab fa-twitter"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCXJeiHhELHvFEM04_3j_KrQ" target="_blank">
                      <i className="fab fa-youtube"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/dashleague/" target="_blank">
                      <i className="fab fa-instagram"/>
                    </a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="#">*/}
                  {/*    <i className="fab  fa-tiktok"/>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </ul>
              </div>
              <div className="header-bottom-nav">
                <ul className="main-menu">
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      <span>Home</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://jp0-1.gitbook.io/dashleague/" target="_blank">
                      <span>Whitepaper</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#roadmap">
                      <span>Roadmap</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="header-center-border">
                <a className="header-logo" >
                  <img src="assets/images/logo.png" style={{width: "100%"}} />
                </a>

              </div>
              {
                (
                  isCorrectNetwork(network) &&
                  account
                ) ? null : (
                  <div className="header-pullout">
                    <ConnectWalletButton />
                  </div>
                )
              }
            </div>

            <div className="col-lg-3">
              <div className="header-right-border" />
              <div className="header-top-nav right-menu">
                <span>Join our <a href="https://discord.gg/mqEY4N4bEa" target="_blank">Discord</a> channel for updates!</span>
              </div>
              <div className="header-bottom-nav">
                <ul className="main-menu right-menu">
                  <li className="nav-item">
                    <a className="nav-link" href="#gameplay">
                      <span>GamePlay</span>
                    </a>
                  </li>
                  {/*<li className="nav-item">*/}
                  {/*  <a className="nav-link" href="https://discord.gg/mqEY4N4bEa" target="_blank">*/}
                  {/*    <span>Community</span>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  
                  <li className="nav-item">
                    <a className="nav-link" href="#faq">
                      <span>FAQ</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://app.dashleague.io/dlc">
                      <span>Play</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-1">
              <div className="edge-option align-middle">
                <a href="https://discord.gg/mqEY4N4bEa" target="_blank" className="support">
                  <i className="fab fa-discord fa-lg"></i>
                </a>
              </div>
            </div>

          </div>
        </div>
      </header>
    </>
  )
}
function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    network: networkSelector(state)
  }
}

export default connect(mapStateToProps)(Header)