import {connect} from "react-redux";

const GetStartArea = () => {
  return (
    <section className="get-start" id="gameplay-s">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 d-flex align-self-center">
            <div className="left-area">
              <div className="section-heading">
                <h5 className="subtitle">
                  Unique Rune & Dungeon Tokenomics
                </h5>
                <h2 className="title ">
                  It Pays To Play
                </h2>
                <p className="text">
                  Our unique rune system allows you to play thousands of unique dungeons by combining different runes at
                  the portal in the game. Each rune has special attributes that govern the <span
                  className="text-orange">level</span>, <span className="text-seagreen">dungeon type</span>, <span
                  className="text-pink">drops</span> and more!
                  <br /><br/>
                    <b><i>...but wait! There is a twist!</i></b>
                    <br/><br/>
                      Each time you portal to a new dungeon it requires a <b className="text-orange">small token fee</b>.
                      Upon beating the dungeon boss, you can <span className="text-seagreen">claim the map</span>, <span
                      className="text-pink">earning the token fees</span> paid for the next 7 days!


                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="right-image">
              <img src="assets/images/runes.png" alt=""/>
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop: '50px'}}>
          <div className="col-lg-5">
            <div className="right-image">
              <img src="assets/images/alliance.png" alt="" />
            </div>
          </div>
          <div className="col-lg-7 d-flex align-self-center order-first order-md-last order-lg-last">
            <div className="left-area">
              <div className="section-heading">
                <h5 className="subtitle">
                  League Tokenomics
                </h5>
                <h2 className="title ">
                  Introducing League Pool
                </h2>
                <p className="text">

                  <b className="text-seagreen">Wait, can you steal maps from other Dashers?
                    <br/><span className="text-pink"> Yes, and now let me introduce "Leagues"!</span></b>
                  <br/><br/>
                    One of the major tokenomic models of DashLeagues is that Dashers can create a "League" by teaming up
                    with other Dashers. When you join a league <span style={{color:'darkorange'}}>you can pool conquered maps</span>,
                    and <span className="text-seagreen">split the token rewards!</span>
                    <br/><br/>
                      And if that wasn't all...
                      <br/><br/>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop: '50px'}}>
          <div className="col-lg-7 d-flex align-self-center">
            <div className="left-area">
              <div className="section-heading">
                <h5 className="subtitle">
                  Map Building Tokenomics
                </h5>
                <h2 className="title ">
                  It Pays To Build
                </h2>
                <p className="text">
                  Once you claim a dungeon, you can customize it, changing the layout, adding your own <span
                  style={{color: 'darkorange'}}>traps</span>, <span className="text-seagreen">monsters</span>, <span
                  className="text-pink">props</span> and more that you acquire by playing the game!
                  <br /><br/>
                    Dashers review dungeons after <span className="text-orange">every raid (win or loss)</span>, <b
                    className="text-seagreen">rewarding up to 15% bonus tokens</b> for <span className="text-pink">building great dungeons</span> for
                    their fellow Dashers!
                    <br/><br/>
                      ..and we are just scratching the surface, the rest you will have to experience in-game!

                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="right-image">
              <img src="assets/images/reviews.png" alt=""/>
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop: '80px'}}>
          <div className="col-lg-5">
            <div className="right-image">
              <img src="assets/images/soul_prism.png" alt=""/>
            </div>
          </div>
          <div className="col-lg-7 d-flex align-self-center order-first order-md-last order-lg-last">
            <div className="left-area">
              <div className="section-heading">
                <h5 className="subtitle">
                  NFT Tokenomics
                </h5>
                <h2 className="title ">
                  Rank Rewards
                </h2>
                <p className="text">
                  Wish your favorite games <span className="text-orange">paid you to play?</span>
                  <br/><br/>
                    We have created a <span className="text-seagreen">reward multiplier</span> system in DashLeague that rewards 
                    higher ranked NFTs such as <span className="text-pink">Legendary</span> or <span
                    className="text-pink">Godly</span> DashLeague NFTs!
                    <br /><br/>
                      Only have a "common" NFT? Great! Through playing the game and <span
                      className="text-orange">"transcend"</span>, Dashers can raise both the <span
                      className="text-seagreen">base stat multiplier</span> and <span
                      className="text-pink">rarity</span> of your NFT!
                      <br/><br/>
                        <small style={{fontSize:'10px'}}><i><sup>*</sup>Highest rarity achievable through re-incarnation
                          is "Legendary"</i></small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
)
};


function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(GetStartArea)