import React from "react";

const FaqGamePlay = () => {

  return (
    <div className="tab-pane active show" id="gameplay" role="tabpanel" aria-labelledby="gameplay-tab">
      <div className="accordion sorteo-accordion" id="accordionExample2">
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#game1"
                      aria-expanded="true" aria-controls="collapseFive"><i
                className="fa fa-question"/> Are our NFTs our playable?
              </button>
            </h2>
          </div>
          <div id="game1" className="collapse show" aria-labelledby="game1"
               data-parent="#accordionExample2">
            <div className="card-body">
              <p>Yes, the <span className="text-orange">NFTs</span> you purchase <span
                className="text-seagreen">are playable</span> in the MMORPG and <span className="text-pink">increase in value</span> as
                you play!</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#game2"
                      aria-expanded="true" aria-controls="game2"><i className="fa fa-question"/> Do our
                NFTs update based on in-game play?
              </button>
            </h2>
          </div>
          <div id="game2" className="collapse show" aria-labelledby="game2" data-parent="#accordionExample2">
            <div className="card-body">
              <p>Yes! The <span className="text-orange">metadata of your NFT</span> update as you play, and
                will reflect <span className="text-seagreen">equipment changes and important events</span>,
                such as if you <span className="text-pink">"reincarnate"</span> to a higher rank!
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#game3"
                      aria-expanded="true" aria-controls="game3"><i className="fa fa-question"/> Are NFTs
                sold with their inventory?
              </button>
            </h2>
          </div>
          <div id="game3" className="collapse show" aria-labelledby="game3" data-parent="#accordionExample2">
            <div className="card-body">
              <p>Yes, any items NFTs are equipped with or <span className="text-orange">carrying at time of sale will transfer</span>.
                However Dashers whom have multiple NFTs may <span className="text-seagreen">transfer/trade inventory</span> to
                their other NFTs prior to sale. <span className="text-pink">All experience, rank, class selection, and skills</span> will
                remain.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#game4"
                      aria-expanded="true" aria-controls="game4"><i className="fa fa-question"></i> What is
                re-incarnation?
              </button>
            </h2>
          </div>
          <div id="game3" className="collapse show" aria-labelledby="game4" data-parent="#accordionExample2">
            <div className="card-body">
              <p>
                Re-incarnation increases the <span
                className="text-orange">base stat multiplier</span> and <span
                className="text-seagreen">rarity</span>. NFTs can be ranked up from <span
                className="text-pink">Uncommon to Legendary</span> (Legendary earns you a piece of the 2.5%
                player pool), however you can not re-incarnate from Legendary to Godly.
                <br/><br/>
                Making Godly NFTs the most valuable in and out of game!
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#game5" aria-expanded="false" aria-controls="game5"><i
                className="fa fa-question"/> How do you earn runes in the game?
              </button>
            </h2>
          </div>
          <div id="game5" className="collapse show" aria-labelledby="game5" data-parent="#accordionExample2">
            <div className="card-body">
              <p>All beginners are given an <span className="text-orange">initial set of runes</span>. Other
                runes you will <span className="text-seagreen">earn by raiding dungeons</span>! Explore all
                the dungeons you can and you may very well <span
                  className="text-pink">find the easter eggs</span> we've been hiding in the game :)</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#game6" aria-expanded="false" aria-controls="game6"><i
                className="fa fa-question">&nbsp;</i> How do you earn monsters, props,etc to customize a dungeon?
              </button>
            </h2>
          </div>
          <div id="game6" className="collapse show" aria-labelledby="game6" data-parent="#accordionExample2">
            <div className="card-body">
              <p>You can earn <span className="text-orange">monsters</span>, <span
                className="text-seagreen">props</span>, and other assets for your own maps by <span
                className="text-pink">playing the game or through the in-game marketplace!</span></p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#game7" aria-expanded="false" aria-controls="game6"><i
                className="fa fa-question"/> Is there PVP?
              </button>
            </h2>
          </div>
          <div id="game7" className="collapse show" aria-labelledby="game6" data-parent="#accordionExample2">
            <div className="card-body">
              <p>
                Dashers can <span className="text-orange">attack other dashers who outside of town</span>,
                during a dungeon raid, but at a cost. If you attack and kill another Dasher during a dungeon
                raid, your <span className="text-seagreen">character title will turn red</span>. While your
                title remains red, if you die, your <span
                className="text-pink">character will drop items</span>, and any dasher in the game can
                attack and kill you (while outside of town) without their title turning red.
                <br/><br/>
                Think about how you might use this game mechanic to protect your dungeons during major
                events!
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#game8" aria-expanded="false" aria-controls="game6"><i
                className="fa fa-question"/> How can I setup a shop in town?
              </button>
            </h2>
          </div>
          <div id="game8" className="collapse show" aria-labelledby="game6" data-parent="#accordionExample2">
            <div className="card-body">
              <p>
                Once released (see roadmap), dashers will need to acquire <span
                className="text-orange">"Deeds"</span>, which entitle them to <span
                className="text-seagreen">in-game real estate</span> to setup their shops or stalls
                throughout the city. <span className="text-pink">Multiple merchant styles</span> will be
                available to dashers. Will you see raw resources, run a potion shop, weapon's shop, or more?
                <br/><br/>
                What kind of merchant will you be?
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default FaqGamePlay;