import {connect} from "react-redux";
import MintButton from "./MintButton";
import Timer from "./Timer";

const FeaturedGameArea = ({seconds, setSeconds, setNftRequestStatus}) => {



  return(
    <section className="featured-game">

      <div className="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="feature-box">
                <div className="feature-box-inner">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="single-feature">
                        <div className="ribbon orange ribbon-top-right"><span>Total : .15 ETH</span></div>
                        <div className="portal-wrapper orange">
                          <div className="portal-frame">
                            <div className="portal" />
                          </div>
                          <div className="portal-character-frame">
                            <img src="assets/images/portal-character2.gif" style={{marginTop: '33px', marginLeft: '-33px'}}
                                 className="portal-character" />
                          </div>
                        </div>

                        <div className="content">
                          <h4 className="title" style={{fontFamily: 'Arcade Normal'}}>
                            Summon 1 <br />Character
                          </h4>
                          <MintButton
                            seconds={seconds}
                            color="darkorange"
                            amount={1}
                            setNftRequestStatus={setNftRequestStatus}
                          />
                          <br /><small style={{fontSize: '12px', color: 'white'}}>0.15 ETH per mint</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="single-feature">
                        <div className="ribbon ribbon-top-right"><span>Total : 1.5 ETH</span></div>
                        <div className="portal-wrapper">
                          <div className="portal-frame">
                            <div className="portal" />
                          </div>
                          <div className="portal-character-frame">
                            <img src="assets/images/portal-character-lg.gif" className="portal-character" />
                          </div>
                        </div>
                        <div className="content">
                          <h4 className="title" style={{fontFamily: 'Arcade Normal'}}>
                            Summon 10 Characters
                          </h4>
                          <MintButton
                            seconds={seconds}
                            color="lightseagreen"
                            amount={10}
                            setNftRequestStatus={setNftRequestStatus}
                          />
                          <br /><small style={{fontSize: '12px',  color: 'white'}}>0.15 ETH per mint</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="single-feature">
                        <div className="ribbon red ribbon-top-right"><span>Total : 3 ETH</span></div>
                        <div className="portal-wrapper red">
                          <div className="portal-frame">
                            <div className="portal" />
                          </div>
                          <div className="portal-character-frame">
                            <img src="assets/images/portal-character3.gif" className="portal-character" />
                          </div>
                        </div>
                        <div className="content">
                          <h4 className="title" style={{fontFamily: 'Arcade Normal'}}>
                            Summon 20 Characters
                          </h4>
                          <MintButton
                            seconds={seconds}
                            color="deeppink"
                            amount={20}
                            setNftRequestStatus={setNftRequestStatus}
                          />
                          <br /><small style={{fontSize: '12px',  color: 'white'}}>0.15 ETH per mint</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="about">

        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="section-heading">

              <h2 className="title">
                <img src="assets/images/logo2.png" />
              </h2>
              <h5 className="subtitle">
                The NFT MMORPG That You Actually Want To Play
              </h5>
              <p className="text">
                Create your own dungeons, claim your own shops in town, mine, farm, and earn tokens while you sleep
              </p>
              <p className="text-center" style={{marginTop: '40px'}}>
                <img src="assets/images/professions.png" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(FeaturedGameArea)