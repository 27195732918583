import { buyNft } from '../store/interactions'
import React, {Component, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Identicon from 'identicon.js';
import Loading from './Loading'
import {
  contractSelector,
  metadataSelector,
  nftStateSelector,
  networkSelector,
  metadataLoadedSelector,
  nftStateLoadedSelector
} from '../store/selectors'
import Hero from './Hero';
import FeaturedGameArea from "./FeaturedGameArea";
import GetStartArea from "./GetStartArea";
import Characters from "./Characters";
import HowToPlayArea from './HowToPlayArea'
import Faqs from "./Faqs";
import HowPlayAreaTwo from "./HowPlayAreaTwo";
import moment from "moment-timezone";
import MyDashers from "./MyDashers";
import RecentWinnersArea from "./RecentWinnersArea";

const Main  = ({dataLoaded}) => {
  const [startDate, setStartDate] = useState(moment.tz("America/New_York"))
  const [endDate, setEndDate] = useState(moment.tz("2021-09-29 20:30", "America/New_York"))

  const [seconds, setSeconds] = useState(false)


  useEffect(()=>{
    const secs = (endDate-startDate);
    if(secs > 0) {
      setSeconds(secs);
    }
  }, [])

  const [nftRequestStatus, setNftRequestStatus] = useState({
    loading: false,
    error: false,
    success: false
  })

  const {loading, success, error} = nftRequestStatus;
  if(loading || success || error) {
    return <Loading nftRequestStatus={nftRequestStatus} setNftRequestStatus={setNftRequestStatus} />
  }




  return (
    <>
      <Hero />
      <FeaturedGameArea seconds={seconds} setSeconds={setSeconds} setNftRequestStatus={setNftRequestStatus}/>
      <GetStartArea />
      <Characters />
      <HowToPlayArea />
      <Faqs />
      <HowPlayAreaTwo/>
      <RecentWinnersArea />
    </>
  )
};

function mapStateToProps(state) {
  const dataLoaded = true;
  return {
    metadata: metadataSelector(state),
    contract: contractSelector(state),
    nftState: nftStateSelector(state),
    network: networkSelector(state),
    dataLoaded
  }
}

export default connect(mapStateToProps)(Main)