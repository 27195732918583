import React, {useEffect, useState} from "react";
import {
  accountSelector,
  contractSelector,
  metadataLoadedSelector,
  metadataSelector, networkSelector,
  nftStateLoadedSelector,
  nftStateSelector
} from "../store/selectors";
import {connect} from "react-redux";
import {buyNft} from "../store/interactions";
import ConnectWalletButton from "./ConnectWalletButton";
import {isCorrectNetwork, requestNetworkChange} from "../utils/MetaMask";

const MintButton = ({dispatch, seconds, color, amount, setNftRequestStatus, account, network}) => {
  const style = (color) ? {background: color} : {};

  if(seconds ){
    return (
      <a href="#" disabled className="mybtn1 link1" style={style}>Locked</a>
    )
  }

  if(! isCorrectNetwork(network)) {
    return (
      <ConnectWalletButton account={false} color={color} />
    )
  }

  if(! account) {
    return <ConnectWalletButton account={false} color={color} />
  }

  return (
    <button
      className="mybtn1 link1"
      style={style}
      onClick={() => buyNft(
        dispatch,
        amount, // Number of NFT's to mint
        setNftRequestStatus
      )}
    >Mint</button>
  )
};

function mapStateToProps(state) {
  const dataLoaded = metadataLoadedSelector(state) && nftStateLoadedSelector(state)
  return {
    metadata: metadataSelector(state),
    contract: contractSelector(state),
    nftState: nftStateSelector(state),
    network: networkSelector(state),
    account: accountSelector(state),
    dataLoaded
  }
}

export default connect(mapStateToProps)(MintButton)