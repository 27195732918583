const Footer = () => {

  return (
    <footer className="footer" id="footer">
      <div className="copy-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left-area">
                <p><small>Copyright © 2021. All Rights Reserved By DashLeague</small>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <ul className="copright-area-links" style={{display: 'none'}}>
                <li>
                  <a href="#">Terms Of Use</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="https://discord.gg/mqEY4N4bEa" target="_blank">Help Center</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;