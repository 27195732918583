import React from "react";

const FaqTokenomics = () => {
  return (
    <div className="tab-pane active show" id="tokenomics" role="tabpanel" aria-labelledby="tokenomics-tab">
      <div className="accordion sorteo-accordion" id="accordionExample3">
        <div className="card">
          <div className="card-header" id="headingSeven">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#token1"
                      aria-expanded="true" aria-controls="collapseSeven"><i
                className="fa fa-question"/> Where is the white paper?
              </button>
            </h2>
          </div>
          <div id="token1" className="collapse show" aria-labelledby="headingSeven"
               data-parent="#accordionExample3">
            <div className="card-body">
              <p>You can find the whitepaper and tokenomics by clicking <a href="https://jp0-1.gitbook.io/dashleague/" target="_blank">here</a>.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingEight">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#token2" aria-expanded="false" aria-controls="collapseEight"><i
                className="fa fa-question"/> What is the fee to raid a dungeon?
              </button>
            </h2>
          </div>
          <div id="token2" className="collapse show" aria-labelledby="headingEight"
               data-parent="#accordionExample3">
            <div className="card-body">
              <p>The <span className="text-orange">cost to raid</span> a dungeon is <span
                className="text-seagreen">dynamic</span> and is based on the <span className="text-pink">rune configuration</span> you
                use to access the dungeon.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#token3" aria-expanded="false" aria-controls="token3"><i
                className="fa fa-question"/> Will DashLeague have it's own marketplace for game assets?
              </button>
            </h2>
          </div>
          <div id="token3" className="collapse show" aria-labelledby="token3" data-parent="#accordionExample3">
            <div className="card-body">
              <p>Yes! dashers will be able to <span
                className="text-orange">trade and buy/sell assets</span> in the <span
                className="text-seagreen">in-game marketplace</span> using <span
                className="text-pink">$DLC</span>. See the roadmap and discord for announcements!
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#token4" aria-expanded="false" aria-controls="token4"><i
                className="fa fa-question"/> How do "League" pools work?
              </button>
            </h2>
          </div>
          <div id="token4" className="collapse show" aria-labelledby="token4" data-parent="#accordionExample3">
            <div className="card-body">
              <p>
                Dashers can <span className="text-orange">add captured maps</span> to a <span
                className="text-seagreen">"League" pool</span> and <span className="text-pink">split the tokens</span> earned
                from admission fees.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#token5" aria-expanded="false" aria-controls="token5"><i
                className="fa fa-question">&nbsp;</i> Can I buy/sell $DLC?
              </button>
            </h2>
          </div>
          <div id="token5" className="collapse show" aria-labelledby="token5" data-parent="#accordionExample3">
            <div className="card-body">
              <p>
                Join the <a href="https://discord.gg/mqEY4N4bEa" target="_blank"
                            className="text-orange">Discord</a> to stay <span className="text-seagreen">up to date</span> on
                the <span className="text-pink">planned developments</span> for $DLC.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqTokenomics;