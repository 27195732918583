const HowToPlayArea = () => {

  return (
    <section className="featured-game roadmap" id="roadmap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="section-heading">
              <h5 className="subtitle" style={{marginTop: '80px'}}>
                Release & Development
              </h5>
              <h2 className="title">
                Roadmap
              </h2>
              <p className="text">
                Every <span className="text-orange">great journey</span> starts <s className="text-seagreen">with the
                first step</s> <span className="text-pink">inside a dungeon!</span><br />
                <small style={{fontSize: '12px', opacity: 0.25}}><i><sup>*</sup>Pssst... HODL your NFTs until New Year's
                  to get a special surprise!</i></small>
              </p>
            </div>
          </div>
        </div>


        <div className="row justify-content-center">

          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img text-center">
                    <img src="assets/images/mint.gif" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <h4 className="title">
                       <span className="btn btn-success">Step Complete</span><br/>Mint 2,500 Genesis NFTs
                    </h4>
                    <p className="text">
                      Successful <span className="text-orange">pre-sale</span> and <span className="text-seagreen">public sale</span> of
                      all mintable DashLeague NFTs
                      <br/><br/>
                        Airdrop "chest" NFTs to all <span className="text-pink">early backers </span> with the first <span
                        className="text-pink">2,500</span> genesis NFTs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img">
                    <img src="assets/images/logo.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <h4 className="title">
                      <span className="btn btn-success">Step Complete</span><br/>
                      Release Alpha Demo
                    </h4>
                    <p className="text">
                      Allow dashers to <span className="text-orange">experience the in-game mechanics</span> by playing the MMORPG without the blockchain.
                      <br/><br/>
                        Testing the <span className="text-seagreen">party</span>, trading, npcs, <span className="text-pink">pets</span>, and other major gaming elements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img">
                    <img src="assets/images/runes.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <small className={'text-muted'}><i>June 15-30th</i></small><br/>
                    <h4 className="title">
                      Official Beta Release
                    </h4>
                    <p className="text">
                      Teleport dashers to town and <span className="text-orange">start playing maps</span> and <span
                      className="text-seagreen">leveling up</span> their NFTs
                      <br/><br/>
                        Enable dashers to <span className="text-pink">claim dungeons</span> and <span
                        className="text-orange">start monetizing</span> from game play
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img">
                    <img src="assets/images/reviews.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <small className={'text-muted'}><i>TBA</i></small><br/>
                    <h4 className="title">
                      Release Map Builder
                    </h4>
                    <p className="text">
                      Enable dashers to <span className="text-orange">build their own dungeons</span> and review
                      dungeons
                      <br/><br/>
                        <b><span className="text-seagreen">Airdrop special map building content</span> to all <span
                          className="text-pink">HODL'ers from initial presale or mint</span> who still own the NFTs at
                          time of this update</b>
                        <br/><br/>
                          Release League <span className="text-orange">"PVP"</span> mini-game
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img">
                    <img src="assets/images/logo.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <small className={'text-muted'}><i>TBA</i></small><br/>
                    <h4 className="title">
                      DashLeague Goes Mobile
                    </h4>
                    <p className="text">
                      Release the <span className="text-orange">mobile version of DashLeagues</span> for iPhone™
                      <br/><br/>
                        Introduce new <span className="text-seagreen">community selected races</span> to celebrate <span
                        className="text-pink">iPhone™ release</span>
                        <br/><br/>
                          <span className="text-orange">Airdrop new races</span> to all HODL'ers from <span
                          className="text-seagreen">initial presale</span> or <span
                          className="text-seagreen">mint</span> who <span
                          className="text-pink">still own the NFTs</span> at time of this update
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </section>
  )
};

export default HowToPlayArea;