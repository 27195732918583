import {connect} from "react-redux";

const Hero = () => {
  return (
    <div className="hero-area" id="top">
      <div className="char-left">
        <img src="assets/images/citizen-man.png" />
      </div>
      <div className="char-right">
        <img src="assets/images/citizen-woman.png" />
      </div>
      <div id="leaves">
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
        <i/>
        <i/>
        <i/>
        <i/>
        <i/>
        <i/>
        <i/>
        <i/>
      </div>
      <div className="container">
        <div className="row">
          <div className="offset-lg-3 col-lg-6 d-flex align-self-center">
            <div className="hero-overlay">
              <div className="left-content">
                <div className="content text-center">
                  <h5 className="subtitle">
                    New Cutting-Edge Crypto Game
                  </h5>
                  <h1 className="title">
                    Play To Earn
                  </h1>
                  <p className="text">
                    DashLeague is a unique MMORPG built on the blockchain
                    <br />
                      Play by minting one of the unique 20,000 Genesis NFTs
                      <br />
                        Some are funky - Some are special
                        <br />All of them raid dungeons
                  </p>
                  <div className="links">
                    <a href="https://app.dashleague.io/dlc" target="_blank" className="mybtn1 link1">Enter App</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
};

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(Hero)